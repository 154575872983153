<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import useNetw from "@useNetw";

// import SellingProduct from './selling-product';
// import TopUsers from './top-users';
// import Activity from './activity';
// import SocialSource from './social-source';

export default {
  page: {
    title: "ดาวน์โหลดโปรแอปฯ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      listTyeOptions: [
        {
          typeId: "U",
          nameTh: "รายบุคคล",
        },
        {
          typeId: "T",
          nameTh: "แบบทีม",
        },
      ],
      userPriority: 0,
      listType: "",
      chart: false,
      title: "ดาวน์โหลดแอปฯ",
      branchId: [],
      localData: [],
      localDataBranch: [],
      dataDashboard: [],
      branchItem: "",
      dataDashboardKpi: [],
      overlayFlag: false,
      startDate: "",
      endDate: "",
      currentPage: 1,
      perPage: 100,
      //   Teamseries:[],
      load: false,
      isLoading: false,
      DateSearch: "",
      branchIdArr: [],
      items: [
        {
          text: "SuriyaIT",
        },
        {
          text: "ดาวน์โหลดแอปฯ",
          active: true,
        },
      ],
      //! Pie
      seriesPie: [], //! ค่่าลูกค้า
      chartOptionsPie: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [], //! ชื่อสาขา
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      //! End pie

      //! Pie Team
      seriesPieTeam: [], //! ค่่าลูกค้า
      chartOptionsPieTeam: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [], //! ชื่อสาขา
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      //! End pie Team
      optionTeam: [],
      teamId: [],

      optionsTeam: {
        chart: {
          id: "Team",
        },
        xaxis: {
          title: "จำนวนลูกค้ามุุ่่งหวัง",
          categories: [],
        },

        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        colors: ["#5b73e8"],
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(y) {
              if (typeof y !== "undefined") {
                return y + " คน";
              }
              return y;
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        title: {
          text: "จำนวนลูกค้ามุ่งหวัง (คน)",
          align: "center",
          floating: true,
        },
      },
      Teamseries: [
        {
          type: "bar",
          name: "จำนวนลูกค้ามุ่งหวัง (คน)",
          data: [],
        },
      ],
    };
  },
  created() {
    // this.getNow();
    this.getLocalData();

    // console.log(this.optionsTeam.xaxis);
    // console.log(this.Teamseries[0]);
  },
  mounted() {},
  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;
      // console.log(this.branchItem);

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = localDataBranch;
      // console.log(user.userPriority);
      this.userPriority = user.userPriority;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card">
      <div class="card-body">
        <div class="row text-center">
          <a
            style="font-size: 50px;"
            href="https://drive.google.com/file/d/1dMmk4EHOvvkDXdNM3iUjAhNfwISRY-5f"
            class="btn btn-success"
            ><i class="uil uil-download-alt" ><br /></i
            >ดาวน์โหลดแอป</a
          >
        </div>
        <!-- <a href="https://drive.google.com/uc?export=download&id=1dMmk4EHOvvkDXdNM3iUjAhNfwISRY-5f" class="btn btn-success">ดาวน์โหลดโปรแกรม</a> -->

        <!--  -->
      </div>
    </div>
  </Layout>
</template>
